.modalContainer {
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  animation: modalIn 300ms;
  &.show {
    // animation: modalOut 300ms forwards;
    display: flex;
  }
}

.modalContent {
  background-color: #ffffff;
  width: 30%;
  max-width: 800px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  > * {
    padding: 0.5rem;
  }
  .modalHeader {
    display: flex;

    .cityLogo {
      width: 100px;
      height: auto;
      align-self: flex-start; // for safari whose hieght: auto not working properly
    }

    .modalTitle {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
      font-weight: 500;
      margin-left: 0.725rem;
      align-self: start;
    }

    .closeBtn {
      cursor: pointer;
      color: #e3e3e3;
      font-size: 3rem;
      line-height: 1rem;

      &:hover {
        color: red;
      }
    }
  }
  .modalBody {
    flex-grow: 4;
    .modalMessage {
      margin: 0;
    }
  }
  .modalFooter {
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes modalIn {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: block;
  }
}

// @keyframes modalOut {
//   from {
//     opacity: 1;
//     display: block;
//   }
//   to {
//     display: none;
//     opacity: 0;
//   }
// }
