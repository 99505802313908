.formWrapper {
  background-color: #f0efeb;
  padding: 2rem;
  max-width: 800px;
  width: 80%;
  max-height: 80%;
  overflow: auto;
}
.formTitle {
  font-weight: 400;
  font-size: 38px;
  line-height: 1.2;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 20px;
  max-width: 800px;
}

.formParagraph {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.formBody {
  & > * {
    margin-bottom: 0.625rem;
  }
}

.submitButton {
  border: none;
  padding: 0.375rem;
  border-radius: 3px;
  font-weight: bold;
  font-size: 1.15rem;

  // background-color: #ffffff;
  &:focus {
    outline: "none";
  }
  &:enabled {
    background-color: rgba($color: #499ad6, $alpha: 0.8);
    color: #ffffff;
  }
  &:hover {
    cursor: pointer;
    &:enabled {
      //   background-color: #c1151f;
      //   color: #ffffff;
      background-color: rgba($color: #499ad6, $alpha: 1);
    }
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  border: 1px solid #c1151f;
  margin: 0 0.725rem;
  padding: 0.375rem;
  border-radius: 3px;
  font-weight: bold;
  font-size: 1.15rem;

  // background-color: #ffffff;
  &:focus {
    outline: "none";
  }
  &:hover {
    cursor: pointer;
    &:enabled {
      background-color: #c1151f;
      color: #ffffff;
    }
  }
}
