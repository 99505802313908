.headerWrapper {
  box-sizing: border-box;
  padding: 0 1rem;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
}

.headerContent {
  display: flex;
  align-items: center;
}

.headerLogo {
  width: 150px;
}

.headerNavContainer ul > * {
  display: inline;
  font-size: 1.2rem;
}

.headerLink a {
  color: red;
  text-decoration: none;
}

.dropdown {
  position: relative;
  display: inline-block;

  .dropbtn {
    background-color: #3498db;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      color: black;
      background-color: #fff;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #ddd;
      }
    }
  }

  &:hover {
    .dropdownContent {
      display: block;
    }
  }
}
