.container {
  max-width: 1440px;
  // max-height: 100vh;
  margin: 0 auto;
}

.fixedSearchTextField {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  width: 100%;
  text-align: center;
  padding: 2rem 0 1rem;
  background-color: #ffffff;
}

.userGrid {
  margin: 5rem auto 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  // grid-template-columns: repeat(auto-fit, minmax(180px, 180px));
  grid-gap: 1.5rem;
  justify-items: center;
}
