.container {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: rgba($color: #eee, $alpha: 1);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  transition: all 0.1s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.5);
  }
  &:active {
    // background-color: rgba($color: #000000, $alpha: 0.2);
    box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.5);

    // transform: translate(1px, 1px);
    transform: scale(1);
  }

  &.disabled {
    background-color: grey;
  }
}

.editIcon {
  position: absolute;
  padding: 0.5rem;
  right: 0px;
  top: 0px;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
}

.letterCircle {
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  padding: 8px;

  background: plum;
  font-size: 6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.disabled {
    background-color: white;
    color: black;
  }
}

.img {
  width: 80%;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
