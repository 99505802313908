.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.module-wrapper {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 5px;
  filter: drop-shadow(0 0 5px #00000020);
  display: block;
}
.mms-report-wrapper {
  display: block;
  margin: 10px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  filter: drop-shadow(0 0 5px #00000020);
  /* for hover animation */
  transition: all 0.1s ease-in-out;
}
/* Hover animation*/
.mms-report-wrapper:hover {
  background-color: #ffffffd3;
  transform: scale(1.01) perspective(1px);
}

/* Report image */
.mms-report-image {
  float: left;
  margin: 10px;
  border-radius: 8px;
}
.mms-header {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 26px;
  padding: 10px;
}
/* Report contnet */
.mms-report-title {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.25em;
  color: #ea0029;
  margin: 0;
  margin-top: 10px;
}
.mms-report-title-large {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.5em;
  color: #ea0029;
  margin: 0;
}
.mms-report-time {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1em;
  margin: 5px 0;
}
/* report details */
.mms-report-info {
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1em;
  margin-top: 10px;
}
.mms-report-info p {
  margin-top: 0;
}
.info-header {
  font-weight: 700;
  color: #e9731d;
  margin: 0;
}
.mms-report-options {
  margin: 10px;
}

/* dropdown selection */
label {
  font-family: "Raleway", sans-serif;
  font-size: 1.2em;
}
.resolution-options {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 2px;
}
/* report options */
ul {
  list-style-type: none;
  display: inline-block;
  margin-left: 0px;
  padding-left: 0px;
}
li {
  margin: 10px 0;
}
.icon-description {
  font-family: "Lato", sans-serif;
  font-size: 1.1em;
  display: inline-block;
  text-align: right;
  margin: 0 5px;
  flex: 1;
  padding-right: 5px;
}
.icon-image {
  float: left;
}
.comments-header {
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 0px;
}

input {
  width: 75px;
}

.loading-icon {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1e6db1;
  align-items: center;
  justify-content: center;
}
